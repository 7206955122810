import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const Navigation: React.FunctionComponent = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <nav className="hero-nav container px-4 px-lg-0 mx-auto">
            <ul className="nav w-100 list-unstyled align-items-center p-0">
                <li className="hero-nav__item"><img className="hero-nav__logo" src="./logo.png" /></li>
                <li id="hero-menu" className={"flex-grow-1 hero__nav-list hero__nav-list--mobile-menu ft-menu" + (showMenu ? " ft-menu--js-show" : "")}>
                    <ul className="hero__menu-content nav flex-column flex-lg-row ft-menu__slider animated list-unstyled p-2 p-lg-0">
                        <li className="hero-nav__item"><a href="#about" className="hero-nav__link">About</a></li>
                        <li className="flex-grow-1">
                            <ul className="nav nav--lg-side list-unstyled align-items-center p-0">
                                <li className="hero-nav__item"><a href="#contact" className="btn btn-primary">Get in touch</a></li>
                            </ul>
                        </li>
                    </ul>
                    <button onClick={() => setShowMenu(false)} className="ft-menu__close-btn animated">
                        <svg className="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clipRule="evenodd"></path>
                            <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </li>
                <li className="d-lg-none flex-grow-1 d-flex flex-row-reverse hero-nav__item">
                    <button onClick={() => setShowMenu(true)} className="text-center px-2">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default Navigation;