import React from "react";
import Articles from "../components/articles";
import Contact from "../components/contact";
import Footer from "../components/footer";
import HeroContent from "../components/heroContent";
import Navigation from "../components/navigation";

const Home: React.FunctionComponent = () => {
  return (
    <>
      <header className="hero container-fluid">
        <Navigation />
        <div className="hero__content container mx-auto"></div>
      </header>

      <div className="block-45 space-between-blocks">
        <div className="container">
          <div className="block__header text-center col-lg-7 p-0 mx-auto">
            <a name="articles"><h1 className="block__title">Page not found</h1></a>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-4 mb-lg-0">

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;