import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Footer: React.FunctionComponent = () => {
    return (
        <div className="block-43 text-center py-5">
            <div className="container">
                <ul className="block-43__list list-unstyled justify-content-center mb-4">
                    <li className="block-43__li-1"><a href="#about" className="block-43__link">About</a></li>
                    {/* <li className="block-43__li-1"><a href="#!" className="block-43__link">Solutions</a></li>
                    <li className="block-43__li-1"><a href="#!" className="block-43__link">Articles</a></li> */}
                </ul>
                <ul className="d-flex justify-content-center list-unstyled p-0">
                    <li className="mx-2"><a href="https://twitter.com/whitedogio" className="block-43__link m-0"><FontAwesomeIcon icon={faTwitter} /></a></li>
                    <li className="mx-2"><a href="https://github.com/whitedogio" className="block-43__link m-0"><FontAwesomeIcon icon={faGithub} /></a></li>
                </ul>
                <p className="block-43__copyrights">Copyright &copy; 2020 White Dog &amp; Company Ltd<br />White Dog &amp; Company Ltd is a company registered in England &amp; Wales (company number 08238412)<br />Crown House, 27 Old Gloucester Street, London, England, WC1N 3AX<br />VAT number GB355028802</p>
            </div>
        </div>
    );
}

export default Footer;